import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-feather";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Cotizar = (props) => {
  const [Datos, setDatos] = useState({
    hectareas: "1",
    nombre: "",
    telefono: "",
    correo: "",
    empresa: "",
    origen: useLocation().pathname,
    auth: "6110e000-1b31-4a95-8fe2-8d3149991e1e",
  });

  const inputVal = (e) => {
    const { name, value } = e.target; //EXTRAE NOMBRE Y VALOR DE INPUT
    setDatos({ ...Datos, [name]: value }); //Seteando valores en el estado de el componente
  };

  const [Paso, setPaso] = useState(1);

  const SubmitForm1 = (e) => {
    e.preventDefault();
    setPaso(2);
  };

  const SubmitForm2 = (e) => {
    e.preventDefault();

    API();
  };

  const API = () => {
    axios
      .post(`https://elsauzagroinsumos.com/API/cotizar.php`, Datos)
      .then((res) => {
        if (res.status == 200) {
          setPaso(3);
          console.log(res.status);
        } else {
          alert("no jala ya");
        }
        console.log(res.data);
      });
  };

  const LimpiarDatos = (e) => {
    setDatos({
      nombre: "",
      telefono: "",
      correo: "",
      empresa: "",
      hectareas: "1",
    });
  };

  return (
    <>
      <div className={"Overlay " + props.open}>
        <div className="Popup" id="Cotizar">
          <button
            className="CloseButton"
            onClick={() => (props.setOpen("false"), setPaso(1))}
          >
            <Icon.X size={24} />
          </button>

          <section
            id="Paso1"
            className="bloqueForm"
            style={{
              display: Paso == 1 ? "block" : "none",
            }}
          >
            <h1 className="forPopup">¿De cuantas hectáreas es su proyecto?</h1>
            <form className="forPopup" onSubmit={(e) => SubmitForm1(e)}>
              <section
                className="alertInfo"
                style={{
                  display: Datos.hectareas < 1 ? "flex" : "none",
                }}
              >
                <Icon.AlertCircle size={24} />
                solo proveemos materiales para una hectárea como minímo
              </section>

              <div className="row">
                <div className="InputContent">
                  <input
                    name="hectareas"
                    type="number"
                    min="1"
                    value={Datos.hectareas}
                    onChange={inputVal}
                    required
                  />
                  <label htmlFor="">Hectareas</label>
                </div>
                <button className="Primary" type="submit">
                  SIGUIENTE
                </button>
              </div>
            </form>
          </section>

          <section
            id="Paso2"
            className="bloqueForm"
            style={{
              display: Paso == 2 ? "block" : "none",
            }}
          >
            <h1>Solicitar cotización</h1>
            <p>Escriba sus datos para poder comunicarnos.</p>

            <form className="forPopup" onSubmit={(e) => SubmitForm2(e)}>
              <div className="InputContent">
                <input
                  name="nombre"
                  type="text"
                  value={Datos.nombre}
                  onChange={inputVal}
                  required
                />
                <label htmlFor="">Escríba su nombre</label>
              </div>

              <div className="InputContent">
                <input
                  name="correo"
                  type="mail"
                  value={Datos.correo}
                  onChange={inputVal}
                  required
                />
                <label htmlFor="">Escríba su correo</label>
              </div>

              <div className="InputContent">
                <input
                  name="telefono"
                  type="tel"
                  value={Datos.telefono}
                  onChange={inputVal}
                  required
                />
                <label htmlFor="">Escríba su teléfono</label>
              </div>

              <div className="InputContent">
                <input
                  name="empresa"
                  type="text"
                  value={Datos.empresa}
                  onChange={inputVal}
                  required
                />
                <label htmlFor="">Escríba el nombre de empresa</label>
              </div>
              <a href="/aviso-de-privacidad" className="aviso" target="_blank">
                *Al presionar el siguiente botón aceptas nuestro
                <span> aviso de privacidad</span>
              </a>
              <div className="row">
                <a href="#" className="Secondary" onClick={() => setPaso(1)}>
                  ANTERIOR
                </a>
                <button type="submit" className="Primary">
                  TERMINAR
                </button>
              </div>
            </form>
          </section>

          <section
            id="Paso3"
            className="bloqueForm"
            style={{
              display: Paso == 3 ? "flex" : "none",
            }}
          >
            <Icon.CheckCircle size={48} />
            <h1 className="forPopup">
              {Datos.nombre}, hemos recibido tu solicitud.
            </h1>
            <p>
              En un máximo de 72 horas nos comunicaremos con usted, mientras
              tanto puede seguir navegando nuestro sitio web.
            </p>
            <Link
              to={"/"}
              className="Secondary"
              onClick={() => (
                props.setOpen("false"),
                setPaso(1),
                LimpiarDatos(),
                window.scrollTo(0, 0)
              )}
            >
              REGRESAR A INICIO
            </Link>
          </section>
        </div>
      </div>
    </>
  );
};

export default Cotizar;
