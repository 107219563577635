import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import RedesSociales from "./RedesSociales";
import ListaMateriales from "./ListaMateriales";

import Logo from "../images/Logo.svg";

const Header = (props) => {
  const [menu, setMenu] = useState("");

  const ToggleMenu = (e) => {
    if (menu == "Menu") {
      setMenu("");
    } else {
      setMenu("Menu");
    }
  };

  const HoverMateriales = (e) => {
    setMenu("Materiales");
  };

  const DesHoverMateriales = (e) => {
    setMenu("");
  };

  return (
    <>
      <header id="escritorio">
        <section className="section-header" id="Main">
          <Link
            to="/"
            onClick={(() => setMenu(""), () => window.scrollTo(0, 0))}
          >
            <img src={Logo} alt="React Logo" id="Logo" />
          </Link>
          <nav id="Navegacion-principal">
            <ul>
              <li onClick={(() => setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/">INICIO</Link>
              </li>
              <li>
                <Link
                  to="/#Materiales"
                  className="a-icon"
                  onMouseEnter={() => HoverMateriales()}
                >
                  MATERIALES
                  <Icon.ChevronDown
                    size={24}
                    style={{
                      transform:
                        menu == "Materiales" ? "rotate(180deg)" : "none",
                    }}
                  />
                </Link>
              </li>
              <li onClick={(() => setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/sobre-nosotros">SOBRE NOSOTROS</Link>
              </li>
            </ul>
          </nav>
          <div className="group-btns">
            <button
              id="Hamburguer"
              onClick={() => ToggleMenu()}
              className={menu == "Menu" ? "active" : ""}
            >
              <span id="uno"></span>
              <span id="dos"></span>
              <span id="tres"></span>
            </button>
            <Link
              to="/contacto"
              className="btnFeliz"
              onClick={(() => setMenu(""), () => window.scrollTo(0, 0))}
            >
              <span>CONTÁCTANOS ⚡</span>
            </Link>
          </div>
        </section>

        <section
          className="section-header"
          id="MaterialesH"
          style={{
            height: menu == "Materiales" ? "100px" : "0px",
            opacity: menu == "Materiales" ? "1" : "0",
            visibility: menu == "Materiales" ? "visible" : "hidden",
          }}
          onMouseLeave={() => DesHoverMateriales()}
        >
          <ListaMateriales {...{ setMenu }}></ListaMateriales>
        </section>

        <section
          className="section-header"
          id="Menu"
          style={{
            height: menu == "Menu" ? "100px" : "0px",
            opacity: menu == "Menu" ? "1" : "0",
            visibility: menu == "Menu" ? "visible" : "hidden",
          }}
        >
          <button
            className="Cotizacion-btn"
            onClick={() => (props.setOpen("true"), setMenu(""))}
          >
            SOLICITAR COTIZACIÓN
          </button>
          <RedesSociales></RedesSociales>
          <Link
            to="aviso-de-privacidad"
            onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}
          >
            AVISO DE PRIVACIDAD
          </Link>
        </section>
      </header>

      <header id="movil">
        <section id="Main">
          <Link to="/">
            <img src={Logo} alt="React Logo" id="Logo" />
          </Link>
          <button
            id="Hamburguer"
            onClick={() => ToggleMenu()}
            className={menu == "Menu" ? "active" : ""}
          >
            <span id="uno"></span>
            <span id="dos"></span>
            <span id="tres"></span>
          </button>
        </section>

        <section
          id="MenuFull"
          style={{
            height: menu == "Menu" ? "calc(100vh - 100px)" : "0",
            opacity: menu == "Menu" ? "1" : "0",
            visibility: menu == "Menu" ? "visible" : "hidden",
            transform: menu == "Menu" ? "" : "scale(0)",
          }}
        >
          <nav id="Navegacion-principal">
            <ul>
              <li onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/">INICIO</Link>
              </li>
              <li
                id="liMaterialesMovil"
                onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}
              >
                <span>
                  <Link to="/#materiales" className="a-icon">
                    MATERIALES
                    <Icon.ChevronRight size={24} />
                  </Link>
                </span>
                <ListaMateriales {...{ setMenu }}></ListaMateriales>
              </li>
              <li onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/sobre-nosotros">SOBRE NOSOTROS</Link>
              </li>
              <li onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/contacto">CONTACTO</Link>
              </li>
              <li onClick={() => (setMenu(""), () => window.scrollTo(0, 0))}>
                <Link to="/aviso-de-privacidad">AVISO DE PRIVACIDAD</Link>
              </li>
            </ul>
          </nav>

          <div className="group-btns">
            <button
              className="Cotizacion-btn"
              onClick={() => (props.setOpen("true"), setMenu(""))}
            >
              SOLICITAR COTIZACIÓN
            </button>
            <RedesSociales></RedesSociales>
          </div>
        </section>
      </header>
      <div
        className={menu != "" ? "Overlay true" : ""}
        style={{ zIndex: "98" }}
      ></div>
    </>
  );
};

export default Header;
