import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import IWb from "../images/IconWablack.svg";

function RedesSociales() {
  return (
    <ul className="RedesSociales">
      <li>
        <a href="tel:3511504699">
          <Icon.PhoneCall size={24} />
        </a>
      </li>

      <li>
        <a
          href="https://api.whatsapp.com/send?phone=523511504699"
          target="_blank"
        >
          <img src={IWb} />
        </a>
      </li>

      <li>
        <a href="mailto:ventas@elsauzagroinsumos.com">
          <Icon.Mail size={24} />
        </a>
      </li>

      <li>
        <a href="https://www.facebook.com/elsauzagroinsumos" target="_blank">
          <Icon.Facebook size={24} />
        </a>
      </li>

      <li>
        <a href="https://www.instagram.com/elsauzagroinsumos" target="_blank">
          <Icon.Instagram size={24} />
        </a>
      </li>
    </ul>
  );
}

export default RedesSociales;
