import { Link } from "react-router-dom";
import * as Icon from "react-feather";

function BarraInferior(props) {
  return (
    <section
      id="BarraInferior"
      style={{
        display: props.props.open == "true" ? "none" : "flex",
      }}
    >
      <button className="Primary" onClick={() => props.props.setOpen("true")}>
        SOLICITAR COTIZACIÓN
      </button>
    </section>
  );
}

export default BarraInferior;
