// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import NavegacionSwiper from "./NavegacionSwiper";
import { useRef } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

// Import Swiper styles

import "swiper/css";
import "swiper/css/navigation";

import Materiales from "../materiales/materiales.json";

function SwiperMateriales(props) {
  const swiperRef = useRef();

  return (
    <div>
      <Swiper
        breakpoints={{
          100: {
            spaceBetween: 24,
            centeredSlides: true,
          },

          600: {
            slidesPerView: 4.33,
          },
        }}
        slidesPerView="auto"
        className="SwiperMateriales"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {Materiales.map((dato) => {
          return (
            <SwiperSlide key={dato.nombre}>
              <Link
                className="materialesSlide"
                to={"/materiales/" + dato.slug}
                onClick={() => window.scrollTo(0, 0)}
              >
                <span
                  style={{
                    backgroundImage:
                      "url(" +
                      dato.imagenes[0] +
                      "),linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
                  }}
                >
                  {dato.nombre}
                  <figure className="verDetalle">
                    <Icon.Plus size={24} />
                  </figure>
                </span>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <NavegacionSwiper {...{ swiperRef }}></NavegacionSwiper>
    </div>
  );
}

export default SwiperMateriales;
