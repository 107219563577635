import { Link } from "react-router-dom";
import * as Icon from "react-feather";
import Logo from "../images/Logo.svg";
import RedesSociales from "./RedesSociales";
import ListaMateriales from "./ListaMateriales";
import IWg from "../images/IconWaGreen.svg";

function Footer(props) {
  const setMenu = (e) => {};
  return (
    <footer>
      <section id="contenidoPrincipal">
        <Link to="/" id="Logo">
          <img src={Logo} />
        </Link>
        <div className="row">
          <h3 className="TituloBordeIzquierdo">CONTÁCTANOS</h3>
          <RedesSociales></RedesSociales>
        </div>
        <div className="row">
          <h3 className="TituloBordeIzquierdo">NUESTROS MATERIALES</h3>
          <ListaMateriales {...{ props, setMenu }}></ListaMateriales>
        </div>
      </section>
      <section id="barraInferior">
        <Link to={"/aviso-de-privacidad"} onClick={() => window.scrollTo(0, 0)}>
          AVISO DE PRIVACIDAD
        </Link>
        <a>© AGROINSUMOS EL SAUZ 2023</a>
      </section>

      <a
        href="https://api.whatsapp.com/send?phone=523511504699"
        id="WaFixed"
        target="_blank"
      >
        <img src={IWg} />
        <span>¡Escríbenos!</span>
      </a>
    </footer>
  );
}

export default Footer;
