import { Link } from "react-router-dom";

function Aviso() {
  let phone = " 351 150 46 99";
  let correo = " ventas@elsauzagroinsumos.com";
  return (
    <main className="pagina" id="Aviso-de-privacidad">
      <strong>
        <h1>AVISO DE PRIVACIDAD</h1>
      </strong>
      <p>
        Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección
        de Datos Personales en Posesión de los Particulares hacemos de su
        conocimiento que la empresa denominada El Sauz Agroinsumos , es
        responsable de recabar sus datos personales, del uso que se le dé a los
        mismos y de su protección por lo cual le informamos lo siguiente:
        <br />
        <br />
        <br />
        <strong>¿QUÉ DATOS PERSONALES RECABAMOS DE USTED?</strong>
        <br />
        Como usuario de nuestra página web le podemos solicitar información
        personal, que varía según el caso, relativa a: <br />
        • Su nombre. <br />
        • Su Teléfono. <br />
        • Su Dirección de correo electrónico. <br />
        • Nombre de su empresa donde labora.
        <br />
        <br />
        <br />
        <strong> ¿PARA QUÉ USAMOS SUS DATOS PERSONALES?</strong>
        <br />
        En esta empresa usamos sus datos personales única y exclusivamente para
        ponerse en contacto con usted y para hacerle llegar material de
        promoción o publicidad mediante su correo electrónico o número
        telefónico.
        <br />
        <br />
        <br />
        <strong>
          ¿CÓMO PUEDE LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?
        </strong>
        <br />• Usted puede limitar el uso y divulgación de su información
        personal a través de los siguientes medios:
        <br />• Presentando su solicitud personalmente en nuestro domicilio
        dirigida al Departamento de Privacidad, departamento encargado de la
        privacidad en esta oficina.
        <br /> • Enviando correo electrónico a la siguiente dirección
        electrónica:{correo}
        <br />• Llamando al número telefónico: {phone}
        <br />
        <br />
        <br />
        <strong>
          ¿CÓMO ACCEDER, RECTIFICAR U OPONERSE AL TRATAMIENTO DE SUS DATOS
          PERSONALES?
        </strong>
        <br />
        En ejercicio de los derechos de acceso, rectificación, cancelación y
        oposición o la revocación del consentimiento, podrán efectuarse
        presentando solicitud por escrito en nuestro domicilio dirigida al
        Departamento de Privacidad, departamento encargado de la privacidad, o
        bien, vía correo electrónico a {correo} o llamando al número {phone}, en
        el entendido de que una vez plasmados en un instrumento notarias, no
        podrá Ud. Ejercer sobre ellos ninguno de los referidos derechos,
        pudiendo hacerlo solamente respecto de los que se conservan en la base
        de datos de El Sauz Agroinsumos
        <br />
        <br />
        <br />
        <strong>¿CON QUIÉN COMPARTIMOS SU INFORMACIÓN Y PARA QUE FINES?</strong>
        <br />
        Sus datos personales solo son tratados por el personal adscrito a El
        Sauz Agroinsumos
        <br />
        <br />
        <br />
        <strong>
          ¿CÓMO CONOCER LOS CAMBIOS AL PRESENTE AVISO DE PRIVACIDAD?
        </strong>
        <br />
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones, por ejemplo, si El Sauz Agroinsumos ve la necesidad de
        realizar transferencias sus datos personales que requieran
        consentimiento, por alguna cuestión en especial, nos comprometemos a
        mantenerlo informado de tal situación a través de alguno de los
        siguientes medios: <br /> • Notificación a su correo electrónico en la
        primera comunicación que tengamos con usted después del cambio al
        presente aviso de privacidad. br <br /> • Usted, como titular, puede
        manifestar su negativa al tratamiento de sus datos personales para las
        finalidades que requieran el consentimiento, teniendo en cuenta que
        puede afectar según el caso, la atención y servicios que El Sauz
        Agroinsumos le preste, si las finalidades o transferencias de estos,
        implican que se lleven a cabo.
        <br />
        <br />
        <br />
        <strong> ¿CÓMO CONTACTARNOS?</strong>
        <br />
        Si usted tiene alguna duda sobre el presente aviso de privacidad, puedo
        dirigirla a:
        <br />• La dirección electrónica: {correo}
        <br />
        • El domicilio de El Sauz Agroinsumos dirigida a Departamento de
        Privacidad, departamento encargado de la privacidad en esta empresa.
        <br /> • A teléfono:{phone}
      </p>
    </main>
  );
}

export default Aviso;
