import React, { useState, useEffect } from "react";
import Cotizar from "./components/Cotizar";
import * as Icon from "react-feather";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Inicio from "./pages/Inicio";
import SobreNosotros from "./pages/SobreNosotros";
import Contacto from "./pages/Contacto";
import Aviso from "./pages/AvisoDePrivacidad";
import Single from "./pages/Single";

import Cuatrocientoscuatro from "./pages/Cuatrocientoscuatro";
import Materiales from "./pages/Single";

function App() {
  const [open, setOpen] = useState("false");
  const [dispositivo, setDispositivo] = useState();

  window.addEventListener("resize", (event) => {
    getWidth();
  });

  const getWidth = (e) => {
    var ancho = window.innerWidth;
    if (ancho > 600) {
      setDispositivo("E");
    } else {
      setDispositivo("M");
    }
    console.log(dispositivo);
  };

  useEffect(() => {
    getWidth();
  }, []);

  return (
    <>
      <Header {...{ open, setOpen }}></Header>
      <Routes>
        <Route path="/" element={<Inicio {...{ dispositivo }} />} title="s" />
        <Route
          exact
          path="materiales/:productId"
          element={<Single {...{ setOpen, open }} />}
        />
        <Route path="sobre-nosotros" element={<SobreNosotros />} />
        <Route path="contacto" element={<Contacto {...{ setOpen }} />} />
        <Route path="aviso-de-privacidad" element={<Aviso />} />
        <Route path="*" element={<Cuatrocientoscuatro />} />
      </Routes>

      <Cotizar {...{ open, setOpen }}></Cotizar>
      <Footer></Footer>
    </>
  );
}

export default App;
