import { Link } from "react-router-dom";
import SwiperMateriales from "../components/SwiperMateriales";
import Collage1 from "../images/Collage1.jpg";
import Collage2 from "../images/Collage2.jpg";
import Collage3 from "../images/Collage3.jpg";
import figureImg from "../images/figureImg.jpg";

import Isotipo from "../images/Isotipo.svg";
import CampoAbierto from "../images/CampoAbierto.jpg";
import SomosIconCalidad from "../images/IconCalidad.svg";
import SomosIconServicio from "../images/IconServicio.svg";
import SomosIconPrecios from "../images/IconPrecios.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState } from "react";

function Inicio(props) {
  const [scroll, setScroll] = useState(0);

  window.addEventListener("scroll", (event) => {
    setScroll(window.scrollY);
    console.log(scroll);
  });

  return (
    <main className="pagina" id="Inicio">
      <section
        className="Row Center"
        id="Portada"
        style={{
          background:
            "background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);",
        }}
      >
        <section className="Col" id="contenido">
          <h1 className="reveal-text">
            Todo para tu invernadero y macrotunel en un solo lugar.
          </h1>
          <p>
            Olvídate de lidiar con un montón de proveedores, en El Sauz
            Agroinsumos tenemos todo lo que necesitas. Contamos con una gran
            variedad de materiales, la mejor calidad y precio del mercado.
          </p>
          <a href="#Somos" className="Terciary">
            Conoce más ⚡
          </a>
        </section>

        <section className="Col">
          <figure id="Collage">
            <figure
              id="imagen1"
              style={{ backgroundImage: "url(" + Collage1 + ")" }}
            ></figure>
            <figure
              id="imagen2"
              style={{ backgroundImage: "url(" + Collage2 + ")" }}
            ></figure>
            <figure
              id="imagen3"
              style={{ backgroundImage: "url(" + Collage3 + ")" }}
            ></figure>
          </figure>
        </section>
      </section>

      <section
        className="Row Full"
        id="Somos"
        style={{
          backgroundImage:
            "url(" +
            CampoAbierto +
            "),linear-gradient(179.98deg, #2B6B32 0.02%, #3A9444 72.82%)",
        }}
      >
        <img src={Isotipo} className="Isotipo" />
        <h1>SOMOS EL SAUZ AGROINSUMOS</h1>
        <p>
          Somos una empresa mexicana con más de 15 años de experiencia en el
          giro agrícola, inicialmente como productores de fresa y jitomate. A
          través de años de trabajo decidimos aprovechar esa experiencia y fue
          así como en febrero de 2018 surge El Sauz Agroinsumos para ofrecerles
          a nuestros clientes el mejor servicio, calidad de materiales y precio.
        </p>

        <div id="BloqueCards">
          <div className="card">
            <img src={SomosIconServicio} alt="" />
            <h3>Ofrecemos el mejor servicio.</h3>
          </div>
          <div className="card">
            <img src={SomosIconCalidad} alt="" />
            <h3>Calidad de materiales.</h3>
          </div>
          <div className="card">
            <img src={SomosIconPrecios} alt="" />
            <h3>Mejores precios.</h3>
          </div>
        </div>
      </section>

      <section className="Row Full" id="Materiales">
        <div id="texto">
          <h2 className="TituloBordeIzquierdo">MATERIALES</h2>
          <p>
            Ofrecemos todos los materiales necesarios para su proyecto de
            cultivo protegido.
          </p>
        </div>
      </section>

      <SwiperMateriales></SwiperMateriales>

      <section
        className="Row Full"
        id="Conocemos"
        style={{ backgroundPosition: scroll / 5 }}
      >
        <h2>“CONOCEMOS LA MEJOR FORMA DE CUIDAR SU INVERSIÓN”</h2>
      </section>

      <section className="Row Center" id="MaterialesFigure">
        <div>
          <h2>MATERIALES</h2>
          <p>
            Ofrecemos todos los materiales necesarios para su proyecto de
            cultivo protegido.
          </p>
        </div>

        <div>
          <figure
            id="FigureImg"
            style={{ backgroundImage: "url(" + figureImg + ")" }}
          >
            <div className="mate">
              <h4>RAFIA PARA TUTOREO</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Faucibus montes
                pellentesque nec curabitur.{" "}
              </p>
              <Link
                to="/materiales/tutoreo"
                onClick={() => window.scrollTo(0, 0)}
                className="Secondary"
              >
                d
              </Link>
            </div>
          </figure>
        </div>
      </section>
    </main>
  );
}

export default Inicio;
