import { Link } from "react-router-dom";
import imgPortada from "../images/SobreNosotros.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";

import "swiper/css";
import "swiper/css/navigation";

import * as Icon from "react-feather";

import NavegacionSwiper from "../components/NavegacionSwiper";

import quoteImg from "../images/quote.svg";
import Opiniones from "./opiniones.json";

import C1 from "../images/Collage3.jpg";
import C2 from "../images/Collage1.1.jpg";
import C3 from "../images/Collage4.jpg";
import C4 from "../images/Collage2.jpg";

function SobreNosotros() {
  const swiperRef = useRef();

  return (
    <main className="pagina" id="SobreNosotros">
      <section className="Row Center" id="Portada">
        <div className="Col">
          <img src={imgPortada} />
        </div>
        <div className="Col" id="texto">
          <h1>Somos el Sauz Agroinsumos</h1>
          <p>
            Somos una empresa mexicana con más de 15 años de experiencia en el
            giro agrícola, inicialmente como productores de fresa y jitomate. A
            través de años de trabajo decidimos aprovechar esa experiencia y fue
            así como en febrero de 2018 surge El Sauz Agroinsumos para
            ofrecerles a nuestros clientes el mejor servicio, calidad de
            materiales y precio.
          </p>
        </div>
      </section>

      <section className="Row Full" id="CollageSN">
        <p>
          Entendemos perfectamente al producto por tantos años de trabajo. Es
          por eso que sabemos muy bien la mejor forma de cuidar su inversión con
          los precios más competitivos del mercado, la mejor calidad en
          garantía, una extensa grama de materiales que le permitirán encontrar
          todo lo que necesita en un solo lugar. Y sobre todo lo que nos hace
          diferentes del resto es que nuestra atención personalizada en todas y
          cada una de nuestras ventas. En el Sauz Agroinsumos estamos para
          servirte.
        </p>

        <div id="graficos">
          <figure
            style={{ backgroundImage: "url(" + C1 + ")" }}
            id="C1"
          ></figure>

          <figure
            style={{ backgroundImage: "url(" + C2 + ")" }}
            id="C2"
          ></figure>

          <figure
            style={{ backgroundImage: "url(" + C3 + ")" }}
            id="C3"
          ></figure>

          <figure
            style={{ backgroundImage: "url(" + C4 + ")" }}
            id="C4"
          ></figure>
        </div>
      </section>
    </main>
  );
}

export default SobreNosotros;
