import { Link } from "react-router-dom";

function Cuatrocientoscuatro() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br />
      Error 404
    </div>
  );
}

export default Cuatrocientoscuatro;
