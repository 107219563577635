import MaterialesData from "../materiales/materiales.json";
import SwiperMateriales from "../components/SwiperMateriales";
import BarraInferior from "../components/BarraInferior";

import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef } from "react";
import prImg from "../materiales/sustrato.jpg";
import NavegacionSwiper from "../components/NavegacionSwiper";

function Materiales(props) {
  const swiperRef = useRef();

  const { productId } = useParams();
  const Resultado = MaterialesData.find((m) => m.slug == productId);

  const cultivosRender = () => {
    return (
      <section id="Cultivos">
        <h3>PARA CULTIVOS DE:</h3>
        <ul>
          {Resultado.cultivos.map((dato) => {
            return (
              <li key={"c" + dato} className="imagen" id={dato}>
                <div className="image"></div>
                <span> {dato}</span>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

  if (Resultado) {
    return (
      <main className="pagina" id="MaterialSingle">
        <section className="Row Center" id="producto">
          <div className="Col" id="contenido">
            <h1 className="nombre">{Resultado.nombre}</h1>
            <p className="contenido">{Resultado.contenido}</p>

            {Resultado.cultivos != undefined ? cultivosRender() : ""}
          </div>
          <div className="Col" id="imagen">
            <Swiper
              loop="true"
              spaceBetween={48}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {Resultado.imagenes.map((dato) => {
                return (
                  <SwiperSlide key={dato.orden}>
                    <img src={dato} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <NavegacionSwiper {...{ swiperRef }}></NavegacionSwiper>
          </div>
        </section>

        <h3 className="TituloBordeIzquierdo">Otros productos</h3>
        <SwiperMateriales></SwiperMateriales>
        <BarraInferior {...{ props }}></BarraInferior>
      </main>
    );
  } else {
    window.location.href = "/#materiales";
  }
}

export default Materiales;
