import { Link } from "react-router-dom";
import * as Icon from "react-feather";

import Materiales from "../materiales/materiales.json";

function ListaMateriales(props) {
  return (
    <nav className="listaMateriales">
      <ul>
        {Materiales.map((dato) => {
          return (
            <li key={dato.nombre}>
              <Link
                to={"/materiales/" + dato.slug}
                onClick={() => (window.scrollTo(0, 0), props.setMenu(""))}
              >
                {dato.nombre}
              </Link>
            </li>
          );
        })}

        <li>
          <Link to="/#Materiales">VER TODO</Link>
        </li>
      </ul>
    </nav>
  );
}

export default ListaMateriales;
