import { Link } from "react-router-dom";
import * as Icon from "react-feather";

function NavegacionSwiper(props) {
  return (
    <ul className="NavegacionSwiper">
      <button
        onClick={() => props.swiperRef.current?.slidePrev()}
        className="Izquierda"
      >
        <Icon.ChevronLeft size={24} />
        <Icon.ChevronLeft size={24} />
      </button>
      <button
        onClick={() => props.swiperRef.current?.slideNext()}
        className="Derecha"
      >
        <Icon.ChevronRight size={24} />
        <Icon.ChevronRight size={24} />
      </button>
      {() => props.swiperRef.current.getActiveIndex()}
    </ul>
  );
}

export default NavegacionSwiper;
