import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-feather";

import { Link } from "react-router-dom";
import imgContacto from "../images/contacto.jpg";
import RedesSociales from "../components/RedesSociales";
import axios from "axios";
import BarraInferior from "../components/BarraInferior";

function Contacto(props) {
  const [Datos, setDatos] = useState({
    nombre: "",
    telefono: "",
    correo: "",
    empresa: "",
    mensaje: "",
    auth: "6110e000-1b31-4a95-8fe2-8d3149991e1e",
  });

  const [Paso, setPaso] = useState(1);

  const SubmitContact = (e) => {
    e.preventDefault();
    API();
  };

  const LimpiarDatos = (e) => {
    setDatos({
      nombre: "",
      telefono: "",
      correo: "",
      empresa: "",
      mensaje: "",
      auth: "6110e000-1b31-4a95-8fe2-8d3149991e1e",
    });
  };

  const inputVal = (e) => {
    const { name, value } = e.target; //EXTRAE NOMBRE Y VALOR DE INPUT
    setDatos({ ...Datos, [name]: value }); //Seteando valores en el estado de el componente
  };

  const API = () => {
    axios
      .post(`https://elsauzagroinsumos.com/API/contacto.php`, Datos)
      .then((res) => {
        if (res.status == 200) {
          console.log(res.status);
          setPaso(2);
        } else {
          alert("no jala ya");
        }
        console.log(res.data);
      });
  };

  return (
    <main id="Contacto">
      <section className="Row Center" id="Portada">
        <div className="Col">
          <form
            onSubmit={(e) => SubmitContact(e)}
            style={{
              display: Paso == 1 ? "flex" : "none",
            }}
          >
            <h1>Contáctanos</h1>
            <p>
              Compartanos la necesidad de su proyecto y un especialista de El
              Sauz Agroinsumos se pondrá en contacto usted.
            </p>
            <div className="InputContent">
              <input
                name="nombre"
                type="text"
                value={Datos.nombre}
                onChange={inputVal}
                required
              />
              <label htmlFor="">Nombre</label>
            </div>

            <div className="InputContent">
              <input
                name="telefono"
                type="tel"
                value={Datos.telefono}
                onChange={inputVal}
                required
              />
              <label htmlFor="">Teléfono</label>
            </div>

            <div className="InputContent">
              <input
                name="correo"
                type="mail"
                value={Datos.correo}
                onChange={inputVal}
                required
              />
              <label htmlFor="">Correo</label>
            </div>

            <div className="InputContent">
              <input
                name="empresa"
                type="text"
                value={Datos.empresa}
                onChange={inputVal}
                required
              />
              <label htmlFor="">Empresa</label>
            </div>

            <div className="InputContent">
              <textarea
                name="mensaje"
                value={Datos.mensaje}
                onChange={inputVal}
                required
              />
              <label htmlFor="">Escríba aquí su mensaje</label>
            </div>
            <a href="/aviso-de-privacidad" className="aviso" target="_blank">
              *Al presionar el siguiente botón aceptas nuestro
              <span> aviso de privacidad</span>
            </a>
            <button className="Primary" type="submit">
              ENVIAR
            </button>
          </form>

          <section
            id="Paso2"
            className="bloqueForm"
            style={{
              display: Paso == 2 ? "flex" : "none",
            }}
          >
            <Icon.CheckCircle size={48} />
            <h1>{Datos.nombre}, hemos recibido tu solicitud.</h1>
            <p>
              En un máximo de 72 horas nos comunicaremos con usted, mientras
              tanto puede seguir navegando nuestro sitio web.
            </p>
            <Link
              to="/"
              className="Secondary"
              onClick={() => (setPaso(1), LimpiarDatos())}
            >
              REGRESAR A INICIO
            </Link>
          </section>
        </div>
        <div className="Col">
          <img src={imgContacto} alt="" />
          <div id="redes">
            <h4>SIGUENOS EN REDES SOCIALES</h4>
            <RedesSociales></RedesSociales>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Contacto;
